<!-- empty placeholder -->
<div *ngIf="category.childItems?.length === 0 && !category.itemFeatured">
    <div class="menu-category__title">THOUGHT LEADERSHIP</div>
    <div class="menu-category__divider"></div>
    <div class="menu-category__feature">
        <ng-container *ngTemplateOutlet="linkArticleImageTemplate; context: { article: thoughtLeadership[index]}"></ng-container>
    </div>
</div>
<!-- for drawing items based on 3 levels -->
<div *ngIf="!category.itemFeatured && maxItemLevel === 3 && category.childItems.length > 0" class="menu-category__block">
    <div class="menu-category__title" [innerHtml]="category.itemName"> </div>
    <div class="menu-category__divider"></div>
    <div
        class="category-item__block grid-3"
        [ngClass]="{'auto-column-grid-correction': category?.childItems?.length > 3}"
    >
        <ng-container
            *ngFor="let item of category.childItems; let idx = index"
        >
            <ng-container *ngTemplateOutlet="linkTemplate; context: { isExternal: isExternal(item.itemURL), item: item, maxItemLevel: maxItemLevel }">     
            </ng-container>
        </ng-container>
    </div>
</div>
<!-- for drawing items based on 4 levels -->
<div *ngIf="!category.itemFeatured && maxItemLevel === 4 && category.childItems.length > 0" class="menu-category__block min-width">
    <div class="menu-category__title" [innerHtml]="category.itemName"></div>
    <div class="menu-category__divider"></div>
    <!-- Menu category styles based on 4 lvls -->
    <div class="d-flex" style="justify-content: space-between; gap: 30px;">
        <div *ngFor="let subCategory of category.childItems">
            <div class="menu-category__subtitle" [innerHtml]="subCategory.itemName"></div>
            <div class="category-item__block flex-column gap-item-4">
                <ng-container
                    *ngFor="let item of subCategory.childItems; let idx = index"
                >
                    <ng-container *ngTemplateOutlet="linkTemplate; context: { isExternal: isExternal(item.itemURL), item: item, maxItemLevel: maxItemLevel }">     
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Featured report  -->
<div *ngIf="category.itemFeatured" class="menu-category__block">
    <div class="menu-category__title" [innerHtml]="category.itemName"></div>
    <div class="menu-category__divider"></div>
    <div class="menu-category__feature">
        <a
            *ngIf="isExternal(category.itemURL)"
            (click)="handleLinkClick(category)"
            class="menu-feature-content__text"
            [href]="category.itemURL"
            target="_blank"
        >
            <ng-container *ngTemplateOutlet="linkImageTemplate; context: { isExternal: isExternal(category.itemURL), category: category }">     
            </ng-container>
        </a>
        <a
            *ngIf="!isExternal(category.itemURL)"
            (click)="handleLinkClick(category)"
            class="menu-feature-content__text"
            [routerLink]="[category.itemURL]"
        >
            <ng-container *ngTemplateOutlet="linkImageTemplate; context: { isExternal: isExternal(category.itemURL), category: category }">     
            </ng-container>
        </a>
    </div>
</div>

<ng-template #linkImageTemplate let-category="category" let-isExternal="isExternal">
    <ng-container>
        <img [src]="category.itemImage" class="menu-category__image" (error)="onImageError($event, category.itemName)" alt="" />
        <div class="menu-category__image_background"></div>
        <div class="menu-feature__content">
            <div
                *ngIf="!!category.itemPromotionalTag"
                class="category-badge"
                [ngClass]="getTagClass(category.itemPromotionalTag)"
            >{{ category.itemPromotionalTag }}</div>
            <a
                *ngIf="isExternal"
                (click)="handleLinkClick(category)"
                class="menu-feature-content__text"
                [href]="category.itemURL"
                target="_blank"
            >
                {{ category.itemDescription }}
            </a>
            <a
                *ngIf="!isExternal"
                (click)="handleLinkClick(category)"
                class="menu-feature-content__text"
                [routerLink]="[category.itemURL]"
            >
                {{ category.itemDescription }}
            </a>
        </div>
    </ng-container>
</ng-template>

<ng-template #linkArticleImageTemplate let-article="article">
    <a
    (click)="handleArticleClick(article)"
    class="menu-feature-content__text"
    target="_blank"
    [href]="article?.url"
    >
    <img [src]="article?.articleImageUrl" class="menu-category__image" (error)="$event.target.src = '/assets/images/tableau_featured_report.png'" alt="" />
    <div class="menu-category__image_background"></div>
    <div class="menu-feature__content">
        <a
            (click)="handleArticleClick(article)"
            class="menu-feature-content__text"
            target="_blank"
            [href]="article?.url"
            >
            {{ article?.title }}
        </a>
    </div>
    </a>
</ng-template>

<ng-template #linkTemplate let-item="item" let-isExternal="isExternal" let-maxItemLevel="maxItemLevel">
    <a
        *ngIf="isExternal"
        [href]="item.itemURL"
        class="category-item"
        (click)="handleLinkClick(item)"
        [class.disabled]="isDisabled(item.itemPromotionalTag)"
        target="_blank"
    >
        <ng-container *ngTemplateOutlet="linkItem; context: { item: item, maxItemLevel: maxItemLevel }">     
        </ng-container>
    </a>
    <a
        *ngIf="!isExternal"
        [routerLink]="[item.itemURL]"
        class="category-item"
        (click)="handleLinkClick(item)"
        [class.disabled]="isDisabled(item.itemPromotionalTag)"
    >
        <ng-container *ngTemplateOutlet="linkItem; context: { item: item, maxItemLevel: maxItemLevel }">     
        </ng-container>
    </a>
</ng-template>

<ng-template #linkItem let-item="item" let-maxItemLevel="maxItemLevel">
    <ng-container *ngIf="maxItemLevel === 3">
        <div class="category-item-title__block">
            <div class="category-item__title" [innerHtml]="item.itemName"></div>
            <div
                *ngIf="!!item.itemPromotionalTag"
                class="category-badge"
                [ngClass]="getTagClass(item.itemPromotionalTag)"
            >{{ item.itemPromotionalTag }}</div>
        </div>
        <div *ngIf="!!item.itemDescription" class="category-item__subtitle">
            {{ item.itemDescription }}
        </div>
    </ng-container>
    <ng-container *ngIf="maxItemLevel === 4">
        <div class="category-item-title__block">
            <div class="category-item__title" [innerHtml]="item.itemName"></div>
            <div
                *ngIf="!!item.itemPromotionalTag"
                class="category-badge"
                [ngClass]="getTagClass(item.itemPromotionalTag)"
            >{{ item.itemPromotionalTag }}</div>
        </div>
    </ng-container>
</ng-template>