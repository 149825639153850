<!-- <p>create-profile works!</p> -->
<div id="profilePage">
    <div id="profileBody">
        <div class="row" style="margin-top: 10px!important; margin-left: 10px!important;">
            <mat-icon>keyboard_arrow_left</mat-icon> <span class="align-self-center" id="backBtn"
                style="cursor:pointer;" [routerLink]="['/profile/home']"> BACK </span>
        </div>
        <div id="contentWrapper">

            <div class="row mb-1">
                <div class="col-3 align-self-center">
                    <h4 style="color: black; float: left;"> Manage Profiles </h4>
                </div>
                <div class="col-5"></div>
                <div class="col-4" style="display: inline-block;">
                    <div class="row">
                        <div class="col-2"></div>
                        <mat-icon class="col-1 align-self-center">search</mat-icon>
                        <mat-form-field class="col-8" style="font-size: 12px!important;" appearance="outline">
                            <input matInput (keyup)="applyFilter($event)" autocomplete="off" #search>
                            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearSearch($event); search.value=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row mb-3 pl-2">
                <div class="col-8">
                    <div class="row">
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 d-flex justify-content-center">
                            <mat-icon style="color:#CE152C;cursor: pointer;" matTooltip="Delete selected">delete
                            </mat-icon>
                        </div>
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 align-self-center p-0 ml-n3">
                            <span class="float-left"  style="cursor:pointer;" (click)="bulkDelete()" > Delete </span>
                        </div>
                        <div class="col-1 text-right  pr-2">
                            <mat-icon style="color:#FFDD00;cursor: pointer;" matTooltip="Favorite Selected">star
                            </mat-icon>
                        </div>
                        <div class="col-1 align-self-center p-0 pr-1">
                            <span class="float-left" style="cursor:pointer;" (click)="setBulkFavorites(true)" > Favorite </span>
                        </div>
                        <div class="col-1 text-right pr-2">
                            <mat-icon style="color:#FFDD00;cursor: pointer;" matTooltip="Favorite Selected">star_border
                            </mat-icon>
                        </div>
                        <div class="col-1 align-self-center p-0 pr-1">
                            <span class="float-left" style="cursor:pointer;" (click)="setBulkFavorites(false)" > UnFavorite </span>
                        </div>
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 align-self-center text-right pr-2">
                            <fa-icon [icon]="disableIcon" matTooltip="Enable Selected"></fa-icon>
                        </div>
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 align-self-center p-0 pr-1">
                            <span class="float-left" style="cursor:pointer;" (click)="setBulkEnableDisable(true)" > Enable </span>
                        </div>
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 align-self-center text-right pr-2">
                            <fa-icon [icon]="disableIcon" matTooltip="Diable Selected"  style="color: red;"></fa-icon>
                        </div>
                        <div *ngIf="userProfileService.canAddOrEdit()" class="col-1 align-self-center p-0 pr-1">
                            <span class="float-left" style="cursor:pointer;" (click)="setBulkEnableDisable(false)" > Disable </span>
                        </div>                        
                    </div>
                </div>
                <div class="col-2"></div>
            </div>

            <div>
                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="lastModifiedDate" matSortDirection="desc">

                        <!-- Select Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef style="width: 7%!important;">
                              <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                                            [aria-label]="checkboxLabel()">
                              </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row" style="width: 7%!important;">
                              <mat-checkbox (click)="$event.stopPropagation();"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)"
                                            [aria-label]="checkboxLabel(row)">
                              </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="profileName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> PROFILE NAME </th>
                            <!-- <td mat-cell *matCellDef="let row" [style.color]="row.active ? '#000000de':'#DFDFE6' "> {{row.profileName}} </td> -->
                            <td mat-cell *matCellDef="let row"> {{row.profileName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="internalNotes">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> INTERNAL NOTES </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.internalNotes}} </td>
                        </ng-container>

                        <ng-container matColumnDef="countryName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> COUNTRY </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.countryCodes.join(', ')}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CREATED BY </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.createdBy}} </td>
                        </ng-container>

                        <ng-container matColumnDef="createdDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CREATED </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.createdDate | date: 'MM/dd/yyyy' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="lastModifiedDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> LAST MODIFIED </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.lastModifiedDate | date: 'MM/dd/yyyy' }} </td>
                        </ng-container>                        

                        <ng-container matColumnDef="favorite">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> FAVORITE </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-icon *ngIf="row.defaultProfile" style="color:#FFDD00;cursor: pointer;" matTooltip="Click to unfavorite"
                                (click)='setFavorite(row)'>star</mat-icon>
                              <mat-icon *ngIf="!row.defaultProfile" matTooltip="Click to set as favorite" style="color:#FFDD00;cursor: pointer; "
                                (click)='setFavorite(row)'>star_border</mat-icon>                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> DISABLED </th>
                            <td mat-cell *matCellDef="let row">
                                <fa-icon class="text-center" *ngIf="row.active" [icon]="disableIcon" matTooltip="Disable"
                                (click)="setProfileStatus(row)"></fa-icon>
                                <fa-icon class="text-center" *ngIf="!row.active" [icon]="disableIcon" matTooltip="Enable"
                                (click)="setProfileStatus(row)" style="color: red;"></fa-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="copy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> COPY </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-icon *ngIf="userProfileService.canAddOrEdit()" matTooltip="Copy profile" (click)="copyProfile(row)" >content_copy</mat-icon> </td>
                        </ng-container> 
                        
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> EDIT </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-icon *ngIf="userProfileService.canAddOrEdit()" matTooltip="edit/view profile" (click)='editProfile(row)' >edit</mat-icon> </td>
                        </ng-container> 

                        <!-- <ng-container matColumnDef="actions">

                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item>
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item>
                                        <span>Favorite</span>
                                    </button>
                                    <button mat-menu-item (click)="copyProfile()" >
                                        <span>Copy</span>
                                    </button>
                                    <button mat-menu-item>
                                        <span>Disable</span>
                                    </button>
                                    <button mat-menu-item>
                                        <span>Delete</span>
                                    </button>                                                                                               
                                </mat-menu>
                            </td>

                        </ng-container> -->


                        <tr class="mb-2" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{search.value}}"</td>
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[ 10, 25, 100]"  (page)="goToPage($event)" showFirstLastButtons ></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>