import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, PromotionalTags } from '@core/models/navbar.model';
import { AnalyticsService } from '@services/analytics.service';
import { Article, ArticlePayload } from 'src/app/drive-time/research/model/article-info';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit {
  @HostBinding('style.flex') styleFlex;

  @Input()
  maxItemLevel?: number;
  @Input()
  category: MenuItem;
  @Input()
  thoughtLeadership: Array<ArticlePayload>;
  @Input()
  index: number;

  @Output()
  handleClick = new EventEmitter();

  constructor(private analyticsService: AnalyticsService) {}

  setFlexStyle() {
    const percentageStep = 20;

    if (this.maxItemLevel === 3) {
      this.styleFlex = `1 0`;
    }

    if (this.maxItemLevel === 4) {
      const flexPercentage = this.category.childItems?.length * percentageStep;

      this.styleFlex = `1 1 ${flexPercentage}%`;
    }
  }

  isDisabled(tag: PromotionalTags | null) {
    return tag?.toLowerCase() === PromotionalTags.COMING_SOON;
  }

  isExternal(url: string) {
    return url?.startsWith('http') || url?.startsWith('https');
  }

  handleLinkClick(item) {
    this.handleClick.emit();
    this.analyticsService.on('track', `CTA Clicked`, {
      cta_title: item.itemName,
      cta_destination_link: item.itemURL,
      cta_link_text: item.itemDescription,
    });
  }

  handleArticleClick(article: Article) {
    const cta_obj = {
      cta_title: article.title,
      cta_destination_link: article.url,
      cta_link_text:article.summary,
    };
    this.handleLinkClick(cta_obj);
  }

  getTagClass(tag: PromotionalTags | null) {
    const lowerCaseTag = tag?.toLowerCase();

    switch (lowerCaseTag) {
      case PromotionalTags.FEATURE:
        return 'featured';
      case PromotionalTags.NEW:
        return 'new';
      case PromotionalTags.POPULAR:
        return 'popular';
      case PromotionalTags.COMING_SOON:
        return 'coming-soon';
      default:
        return null;
    }
  }

  ngOnInit(): void {
    this.setFlexStyle();
  }

  onImageError(event: Event, name: string) {
    const target = event.target as HTMLImageElement;
    target.src = name === "Featured Training" ? '/assets/images/business-teamwork.png' : '/assets/images/tableau_featured_report.png'
  }
}
