import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { empty, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/spinner.service';

import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AllProfilesResolver implements Resolve<any> {
  constructor(private profileService: ProfileService, private spinnerService: SpinnerService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.spinnerService.show(' Loading Profiles. Please wait..');
    return forkJoin([
      this.profileService.getAllProfiles('USA'),
      this.profileService.getAllProfiles('CAN')
    ]).pipe(
      map(([usaProfile, canProfile]) => [...usaProfile, ...canProfile]),
      catchError((error) => {
        console.log('error fetching all profiles ' + error);
        return empty();
      }),
    )
  }
}
